<template>
  <div v-if="template">
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>
    <v-card :elevation="isEmbedded ? 0 : 2">
      <page-header v-if="template" :title="template.name" :img="template.img" />

      <v-card-text>
        <p class="text-bold">{{ template.summary }}</p>
        <vue-markdown class="markdown mb-4" :html="false" :source="template.description" />
        <div v-if="template.rules">
          <h4>How it works</h4>
          <vue-markdown class="markdown mb-4" :html="false" :source="template.rules" />
        </div>
        <div v-if="template.parameters && template.parameters.length">
          <h4>Additional options for this event</h4>
          <ul class="mb-4">
            <li v-for="(item, idx) in template.parameters" :key="idx">{{ item.label }}</li>
            <li v-for="(item, idx) in (template.decorators||[]).filter(x => !x.if_parameter)" :key="`${idx}_decorator`">{{ $helpers.displayText(siteData.decorator_types, item.type) }}</li>
          </ul>
        </div>
        <p>
        <v-btn color="primary" :to="createEventRoute">Start This Challenge</v-btn>
        </p>
      </v-card-text>

      <div v-if="template.tags && template.tags.includes('MAP')">
      <v-card-title class="subheader">Compatible Journeys</v-card-title>
      <v-card-text>You can combine this challenge with one of the following journeys.</v-card-text>
      <v-slide-group
        multiple
        show-arrows
      >
        <v-slide-item
          v-for="(item, idx) in mapTemplates"
          :key="idx"
        >
          <MapTemplateCard :item="item" :to="{name: 'challengeJourneyView', params: {id : item.id }}" class="ma-2" style="width:200px"/>
        </v-slide-item>
      </v-slide-group>      
      </div>

      <div class="grey lighten-3 py-0 my-4">
        <div class="container">
          <v-row>
            <v-col cols="12" md="12">
              <h1 class="subtitle">How it works</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <h2 class="subtitle">1. Create</h2>
              <p>
                Create your event based on this ready-to-go template.
              </p>
              <ul>
                <li>Customize the configuration based on your preferences.</li>
                <li>Personalize your event by uploading your logo and artwork.</li>
                <li>Publish the event.</li>
              </ul>
            </v-col>
            <v-col cols="12" md="4">
              <h2 class="subtitle">2. Start</h2>
              <p>
                Invite your participants using our template emails, or write your own.
              </p>
              <ul>
                <li>Invite your team to join.</li>
                <li>Users can join with just a few clicks.</li>
                <li>The event starts automatically on the configured start date.</li>
              </ul>
            </v-col>
            <v-col cols="12" md="4">
              <h2 class="subtitle">3. Go!</h2>
              <p>
                It's go time! Enjoy and engage.
              </p>
              <ul>
                <li>Build hype around your event through the social feed</li>
                <li>Encourage your team to join the event and be active</li>
                <li>Participant connect their favorite device to track</li>
              </ul>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" align="center">
              <v-divider class="mt-4 mb-4" />
              <v-btn color="primary" x-large outlined rounded :to="createEventRoute">Start Now</v-btn>
              
              <v-btn color="primary" text rounded :to="{name: 'contact' }">Learn More</v-btn>
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="">
        <div class="container">
          <v-row>
            <v-col cols="12" md="12">
              <h1 class="subtitle">Learn More</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="3" v-if="hasTag('MAP')" class="d-flex flex-column">
              <big-button 
                elevated 
                icon="fa-map"
                title="Virtual Journeys" 
                text="Customized progress maps enhance challenge engagement."
                to="/product/benefits/maps-virtual-journeys" 
                class="d-flex flex-column grow"
                />
            </v-col>
            <v-col cols="12" sm="4" md="3" v-if="hasTag('TEAMS')" class="d-flex flex-column">
              <big-button 
                elevated 
                icon="fa-users"
                title="Teams" 
                text="Teams can be created to add another layer of fun competition between departments, offices, or other groups."
                to="/product/benefits/teams-collective" 
                class="d-flex flex-column grow"
                />
            </v-col>
            <v-col cols="12" sm="4" md="3" v-if="hasTag('STEP_BASED')" class="d-flex flex-column">
              <big-button 
                elevated 
                icon="fa-shoe-prints"
                title="Step-based" 
                text="Make every step count!"
                to="/product/challenges/step-challenges" 
                class="d-flex flex-column grow"
                />
            </v-col>
            <v-col cols="12" sm="4" md="3" v-if="hasTag('ACTIVITY_BASED')" class="d-flex flex-column">
              <big-button 
                elevated 
                icon="fa-stopwatch"
                title="Activity-based" 
                text="Fun and engaging fitness challenges."
                to="/product/challenges/time-based-challenges" 
                class="d-flex flex-column grow"
                />
            </v-col>
            <v-col cols="12" sm="4" md="3" class="d-flex flex-column">
              <big-button 
                elevated 
                icon="fa-paint-roller"
                title="Customize the look 'n feel" 
                text="Your challenge is a unique initiative and should look that way!"
                to="/product/benefits/full-customization" 
                class="d-flex flex-column grow"
                />
            </v-col>
          </v-row>
          <v-row v-if="template.resources">
            <v-col cols="12" md="12">
              <h1 class="subtitle">More related resources</h1>
            </v-col>
            <v-col v-for="(article, idx) in blogPosts" :key="idx" cols="4" md="4">
              <content-card :title="article.title" :img="article.thumbnail" />
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="green white--text py-6 py-md-12 my-4">
        <div class="container">
          <v-row>
            <v-col cols="12" md="12">
              <h2 class="subtitle">Go Live Today!</h2>
              <h1 class="subtitle">Create your personalized fitness challenge within minutes!</h1>
              <p class="mb-0 mt-4">
                <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
                <v-btn v-if="false" class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
                <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
              </p>
            </v-col>
          </v-row>
        </div>
      </div>

      <v-card-title class="subheader">More ready-to-go challenges</v-card-title>
      <v-card-text>Explore more ready-to-go challenges.</v-card-text>
      <v-slide-group
        multiple
        show-arrows
      >
        <v-slide-item
          v-for="(item, idx) in templates.filter(x => x.id != template.id).slice(0, 5)"
          :key="idx"
        >
          <EventTemplateCard :item="item" :to="{name: 'challengeTemplateView', params: {id : item.id }}" class="ma-2" style="width:200px"/>
        </v-slide-item>
        <v-slide-item>
          <v-card :to="{name: 'challenges'}" color="grey lighten-3" class="ma-2 pa-2 d-flex flex-column grow justify-center" width="150px" >
            <h3>View All <v-icon small class="ms-2">fa-chevron-right</v-icon></h3>
            <p>Explore all templates</p>
          </v-card>
        </v-slide-item>
      </v-slide-group>      

    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';

import assetsService from "@/services/assetsService";
import cmsService from "@/services/cmsService";
import eventService from "@/services/eventService";
import PageHeader from '@/components/PageHeader.vue'
import VueMarkdown from '@/components/VueMarkdown.vue'
import RaceResultsMap from "@/components/RaceResultsMap.vue";
import ContentCard from '@/components/generic/ContentCard.vue'
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import MapTemplateCard from '@/components/templates/MapTemplateCard.vue'
import siteData from '@/data/site.json'
import ShareButton from "@/components/ShareButton";
import tenants from '@/data/tenants.config'
import BigButton from '@/components/BigButton.vue';
const tenant = tenants.current();

export default {
  name: "ViewTemplate",
  components: {
    PageHeader,
    VueMarkdown,
    RaceResultsMap,
    ShareButton,
    MapTemplateCard,
    EventTemplateCard,
    BigButton,
    ContentCard,
  },
  props: {
  },
  data() {
    return {
      siteData: siteData,
      dark: false,
      templates: null,
      template: null,
      mapTemplates: null,
      allBlogs: null,
    };
  },
  async beforeRouteUpdate (to, from, next) {
    // react to route changes...
    await this.load(to.params.id);
    next();
  },
  async mounted() {
    await this.load(this.$route.params.id);
    this.allBlogs = await cmsService.getCollection('blog-posts');

  },
  methods: {
    async load(templateId) {
      this.mapTemplates = (await assetsService.getMapTemplates()).data.data;
      this.templates = (await assetsService.getEventTemplates()).data.data;
      this.template = this.templates.find(x => x.id == templateId);
      await this.updateMetaTags();
    },

    hasTag(tag) {
      return this.template && this.template.tags && this.template.tags.includes(tag);
    },

    async updateMetaTags() {
      const meta = {
        title: `View the ready-to-go challenge ${this.template.name} on ${tenant.name}.`, 
        description: this.template.description,
        image: this.template.image_url,
      }; 
      EventBus.$emit('page-header-change', meta);
    },


  },
  watch: {
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
    createEventRoute() {
      return this.template && {name: 'eventmanagerCreate', query: {templateId: this.template.id, referral: this.$route.query.referral, license: this.$route.query.license, participants: this.$route.query.participants, support: this.$route.query.support, currency: this.$route.query.currency, groupId: this.$route.query.groupId }};
    },
    blogPosts() {
      if (!this.template || !this.template.resources || !this.allBlogs) {
        return [];
      }
      let blogs = this.template.resources.map(x => cmsService.findItem(this.allBlogs, x)).filter(x => x != null);
      return blogs;

    },
    breadcrumbItems() { 
      return !this.template  ? [] : [
        { text: 'Ready-to-go challenges', exact: true, to: { name: 'challenges'} },
        { text: this.template.name, disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed';
    },
  },

};
</script>

<style lang="scss">
  .logo-container { overflow: inherit !important; margin-bottom: 42px;}
  .logo-container .v-responsive__sizer { padding-bottom: 0!important;}
  .logo-container .display-2 { display: inline-block; margin-left: 250px;}
  .logo-container.container-xs .display-2 { margin-left: 150px;}
  .logo-container.container-sm .display-2 { margin-left: 200px;}
  .event-logo { background-color: white; display: inline-block !important; left: 16px; position: absolute !important; bottom: -32px; }
  .event-logo img { max-width: 200px; }
  .container-xs .event-logo img { max-width: 100px; }
  .container-sm .event-logo img { max-width: 150px; }

  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>

